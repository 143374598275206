<script>
  import { onMount } from "svelte";
  import QRCode from "qrcode";
  import firebase from "firebase/app";
  import "firebase/database";

  const SCENES = [
    "Scene_01",
    "Scene_02",
    "Scene_03",
    "Scene_04",
    "Scene_05",
    "Scene_06",
    "Scene_07",
    "Scene_08",
    "Scene_09",
    "Scene_10V",
    "Scene_11",
    "Scene_12",
    "Scene_13",
    "Scene_14V",
    "Scene_16",
    "Scene_18"
  ];

  var firebaseConfig = {
    apiKey: "AIzaSyC9BSWak399OJ9h21NWS-9LYcgWLByEskY",
    authDomain: "vrar-2655c.firebaseapp.com",
    databaseURL: "https://vrar-2655c.firebaseio.com",
    projectId: "vrar-2655c",
    storageBucket: "",
    messagingSenderId: "748517332965",
    appId: "1:748517332965:web:eeb75a64d993baf97c410c",
    measurementId: "G-3JLJQTPM6M"
  };

  let qr = null;
  let state = null;

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  const db = firebase.database().ref();
  const session = db.child("session").push();
  session.on("value", snap => {
    state = snap.val();
    console.log("session", state);
  });

  onMount(async () => {
    let androidAppId = "de.healthmedia.vrar_vrdemo";
    let iosAppId = "de.healthmedia.vrarVrdemo";
    let iosStoreId = "123"; // TODO
    let link = encodeURI("https://vrar.arztcme.de/session/" + session.key);
    let url = `https://vrar.page.link/?apn=${androidAppId}&ibi=${iosAppId}&isi=${iosStoreId}&link=${link}`;
    qr = await QRCode.toDataURL(url);
  });

  function changeScene(scene) {
    session.update({ scene });
  }
</script>

<style>

</style>

<h1>VR-AR-Med²</h1>

{#if qr != null}
  <img src={qr} alt="QR Code" />
  <br />
{/if}

{#if state != null}
  <code style="font-family: Consolas; white-space: pre-wrap;">
    {JSON.stringify(state, null, 3)}
  </code>

  <br />
  <br />
  {#each SCENES as scene}
    <button
      style="margin-right: 1rem; {scene === state.scene ? 'background-color: cornflowerblue;' : ''}"
      on:click={e => changeScene(scene)}>
      {scene}
    </button>
  {/each}
{/if}
