import App from "./App.svelte";

// all methods that were called till now and stored in queue
// needs to be called now
let globalObject = window[window["VRAR-Widget"]];
let queue = globalObject.q;
if (queue) {
	for (var i = 0; i < queue.length; i++) apiHandler(...queue[i]);
}

// override temporary (until the app loaded) handler
// for widget's API calls
globalObject = apiHandler;

function apiHandler(element) {
	// let ATTRIBUTE_TARGET = "data-target";

	// let s = document.currentScript;
	// console.log("current script:", s);
	// let r = null;
	// if (s.hasAttribute(ATTRIBUTE_TARGET)) {
	//     let id = s.getAttribute(ATTRIBUTE_TARGET);
	//     r = document.getElementById(id);
	// }
	// if (r === null) {
	//     let p = s.parentNode;
	//     console.log("parent of script:", p);
	//     r = document.createElement("div");
	//     p.insertBefore(r, s);
	// }
	// console.log("our root:", r);

	new App({ target: element });
}
